<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.shop") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="grid gap-4">
      <div class="flex flex-col">
        <h2 class="mb-2">{{ t("headings.products") }}</h2>
        <div class="card">
          <custom-table
            :fields="productFields"
            :items="products"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="searchFields"
            :showHeader="true"
            sortBy="stamp"
            :sortFields="sortFields"
            @row-click="() => null"
          >
            <template #cell(addToCart)="{ item }">
              <form-checkbox
                :id="`checkbox-${item.id}`"
                :invalid="false"
                :variant="company.primary"
                v-model="selectedProducts[item.id]"
              />
            </template>
            <template #cell(price)="{ value }">
              {{ value / 100 }}
            </template>
            <template #cell(available)="{ value }">
              {{ value ? t("labels.yes") : t("labels.no") }}
            </template>
          </custom-table>
        </div>
      </div>
      <div class="flex flex-col">
        <h2 class="mb-2">{{ t("headings.shopping_cart") }}</h2>
        <div class="card">
          <custom-table
            :fields="cartFields"
            :items="cartItems"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="false"
            :searchFields="searchFields"
            :showHeader="true"
            sortBy="stamp"
            :sortFields="sortFields"
            @row-click="() => null"
          >
            <template #cell(price)="{ value }">
              {{ value / 100 }}
            </template>
            <template #cell(available)="{ value }">
              {{ value ? t("labels.yes") : t("labels.no") }}
            </template>
            <template #cell(actions)="{ item }">
              <div class="flex justify-end">
                <form-button
                  :command="() => (selectedProducts[item.id] = false)"
                  :icon="TrashIcon"
                  :id="`remove-${item.id}-from-cart-button`"
                  label=""
                  textVariant="light"
                  type="button"
                  variant="danger"
                />
              </div>
            </template>
          </custom-table>
          <div class="p-4 text-right">
            <div class="flex justify-end">
              <div class="flex gap-2 p-4">
                <label class="text-lg">{{ t("labels.total") }}:</label>
                <span class="text-lg">{{ total / 100 }} €</span>
              </div>
            </div>
            <hr class="my-4 border-slate-300" />
            <form-button
              :command="() => order()"
              :disabled="!cartItems.length || waiting"
              id="order-button"
              :label="'order'"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PlusIcon, TrashIcon } from "@heroicons/vue/24/solid";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import FormCheckbox from "../components/form/FormCheckbox.vue";
import CustomTable from "../components/table/CustomTable.vue";
export default {
  components: {
    Breadcrumb,
    CustomTable,
    FormButton,
    FormCheckbox,
    PlusIcon,
    TrashIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const estate = computed(() => store.state.estate.estate);
    const products = computed(() => store.state.product.products);

    const waiting = computed(() => {
      if (store.state.order.waiting) return true;
      if (store.state.product.waiting) return true;
      return false;
    });

    const productFields = ref([
      {
        key: "addToCart",
        label: "add_to_cart",
      },
      {
        key: "name",
        label: "name",
      },
      {
        key: "description",
        label: "description",
      },
      {
        key: "estimatedDelivery",
        label: "estimated_delivery",
      },
      {
        key: "price",
        label: "price",
      },
    ]);
    const searchFields = ref(["name"]);
    const sortFields = ref(["name", "available", "price"]);

    const cartFields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "description",
        label: "description",
      },
      {
        key: "estimatedDelivery",
        label: "estimated_delivery",
      },
      {
        key: "price",
        label: "price",
      },
      {
        key: "actions",
        label: "empty",
      },
    ]);

    const selectedProducts = ref({});

    const cartItems = computed(() =>
      products.value.filter((product) => {
        for (const [key, value] of Object.entries(selectedProducts.value)) {
          return key == product.id && value;
        }
      })
    );

    const total = computed(() =>
      cartItems.value.reduce(
        (accumulator, cartItem) => accumulator + parseInt(cartItem.price),
        0
      )
    );

    const order = () => {
      const data = {
        estateId: estate.value.id,
        productIds: cartItems.value.map((product) => product.id),
      };
      store.dispatch("order/createOrder", data);
    };

    onMounted(() => {
      store.dispatch("product/getProducts", estate.value.id);
    });

    return {
      cartFields,
      cartItems,
      company,
      order,
      PlusIcon,
      productFields,
      products,
      router,
      searchFields,
      selectedProducts,
      sortFields,
      t,
      total,
      TrashIcon,
      waiting,
    };
  },
};
</script>
